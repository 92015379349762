<template>
    <div class="login-view">
        <n-card class="card" title="登录">
            <n-form ref="loginForm" :model="formData" :rules="formRule">
                <n-form-item label="用户名" path="username">
                    <n-input
                        v-model:value="formData.username"
                        placeholder="LDAP用户名"
                        autofocus
                    ></n-input>
                </n-form-item>
                <n-form-item label="密码" path="password">
                    <n-input
                        type="password"
                        v-model:value="formData.password"
                        @keyup="onPasswordKeyUp"
                    ></n-input>
                </n-form-item>
                <n-form-item>
                    <n-button
                        block
                        type="primary"
                        :loading="loading"
                        @click="submit"
                        >登录</n-button
                    >
                </n-form-item>
            </n-form>
        </n-card>
    </div>
</template>

<style lang="less" scoped>
.login-view {
    flex-grow: 1;
    background: #fff url('../../assets/bg.png') no-repeat;
    background-position: 0 100%;
    background-size: 100% auto;
    .card {
        margin: 160px auto;
        width: 450px;
        border-radius: 12px;
        box-shadow: 0 8px 30px 0 rgba(0,0,0,0.14), 0 4px 12px 0 rgba(0,0,0,0.08);
    }
}
</style>

<script setup>
import { ref } from 'vue';

import { NCard, NForm, NFormItem, NButton, NInput, useMessage } from 'naive-ui';

import { userLogin } from '@/common/API';

import { isEmptyArray } from '@/common/tools';

import { useRoute, useRouter } from 'vue-router';

import { useUserStore } from '@/stores/global';

const route = useRoute();

const router = useRouter();

const store = useUserStore();

const message = useMessage();

const loginForm = ref();

let formData = ref({
    username: '',
    password: '',
});

let formRule = ref({
    username: {
        required: true,
        message: '请输入LDAP用户名',
        trigger: 'submit',
    },
    password: {
        required: true,
        message: '请输入密码',
        trigger: 'submit',
    },
});

let loading = ref(false);

function onPasswordKeyUp(e) {
    if (e.code === 'Enter') {
        submit();
    }
}

function submit() {
    loginForm.value.validate(async err => {
        if (err) {
            return;
        }
        try {
            loading.value = true;
            let res = await userLogin(
                formData.value.username,
                formData.value.password
            );
            loading.value = false;
            if (res.error_no !== 0) {
                message.error('用户名或密码错误');
                return;
            }
            store.setCurrentUser({
                username: res.data.userName,
                roles: isEmptyArray(res.data.role_list)
                    ? ['guest']
                    : res.data.role_list,
            });

            router.replace(route.query.refer || '/?login=1');
        } catch (e) {
            loading.value = false;
            message.error('未知异常导致加载失败');
        }
    });
}
</script>
